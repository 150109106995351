import Service from "../Service";

const resource = "task/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    ticworkers(parameters, requestID) {
        return Service.post(resource + "ticworkers", parameters, {
            params: { requestID: requestID },
        });
    },
    ticproyect(parameters, requestID) {
        return Service.post(resource + "ticproyect", parameters, {
            params: { requestID: requestID },
        });
    },
    areabyname(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "areabyname", obj, {
            params: { requestID: requestID },
        });
    },
    sendmail(task, requestID) {
        return Service.post(resource + "sendmail", task, {
            params: { requestID: requestID },
        });
    },

    taskbystatus(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "taskbystatus", obj, {
            params: { requestID: requestID },
        });
    },

    ticketbymonth(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticketbymonth", obj, {
            params: { requestID: requestID },
        });
    },

    ticketbyresponsible(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticketbyresponsible", obj, {
            params: { requestID: requestID },
        });
    },

    ticketbyarea(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticketbyarea", obj, {
            params: { requestID: requestID },
        });
    },
    
    ticTargetbyProy(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticTargetbyProy", obj, {
            params: { requestID: requestID },
        });
    },
    ticConditionByTask(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticConditionByTask", obj, {
            params: { requestID: requestID },
        });
    },
    ticketbylastmonth(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "ticketbylastmonth", obj, {
            params: { requestID: requestID },
        });
    },
    ticketSummary(parameters, requestID) {
        return Service.post(resource + "ticketSummary", parameters, {
            params: { requestID: requestID },
        });
    },
    saveCondition(cmp, requestID) {
        return Service.post(resource + "saveCondition", cmp, {
            params: { requestID: requestID },
        });
    },
    TicTaskConditionTask(cmp, requestID) {
        return Service.post(resource + "TicTaskConditionTask", cmp, {
            params: { requestID: requestID },
        });
    },
    tictaskBystatusTask(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "tictaskBystatusTask", obj, {
            params: { requestID: requestID },
        });
    },
    ticketGeneralSummary(parameters, requestID) {
        return Service.post(resource + "ticketGeneralSummary", parameters, {
            params: { requestID: requestID },
        });
    },
    convertexcel(obj, requestID) {
        return Service.post(resource + "convertexcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        })
    },
};